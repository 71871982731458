import { StaticImage } from "gatsby-plugin-image"
import * as React from "react"
import Video from "../video/ship-hd.mp4"

import BulkLogistics from "../images/logo/bulk-logistics-white.svg"
import EnergyLogistics from "../images/logo/energy-logistics-white.svg"
import AssetManagement from "../images/logo/asset-management-white.svg"
import LogisticPark from "../images/logo/mmlp-white.png"

import BulkLogisticsWhite from "../images/logo/bulk-logistics-white-text.svg"
import EnergyLogisticsWhite from "../images/logo/energy-logistics-white-text.svg"
import AssetManagementWhite from "../images/logo/asset-management-white-text.svg"
import LogisticParkWhite from "../images/logo/mmlp-white-text.png"

import Layout from "../components/layout"
import Seo from "../components/seo"
import { Link } from "gatsby"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faFilePdf } from "@fortawesome/free-solid-svg-icons"

const IndexPage = ({ location }) => (
    <Layout location={location}>
        <Seo title="Home" />
        <div className="main">
            <div className="container">
                <div className="row">
                    <div className="col-12 main-video-col">
                        <div className="main-video">
                            <video className="hero-video h-100" muted playsInline autoPlay loop width="100%">
                                <source src={Video} type="video/mp4" />
                            </video>
                            <div className="main-video-overlay">
                                <div className="row h-100">
                                    <div className="col-12 d-flex flex-column justify-content-end">
                                        <h1 className="text-light">We Are Delta Corp Holdings</h1>
                                        <h2 className="mt-2 text-light">
                                            Engaged in logistics, fuel supply, asset management & energy transition
                                        </h2>
                                        <Link to="/about-us" className="btn btn-outline-light d-block mt-3 main-about-btn">
                                            About Us
                                        </Link>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="row">
                    <div className="col-12">
                        <div className="main-content">
                            <div className="row">
                                <div className="col-12">
                                    <div className="main-content-first-section mt-md-5 mt-3 pb-md-5 pb-3 mb-md-5 mb-3">
                                        <div className="row">
                                            <div className="col-md-7 col-12">
                                                <div className="gray-bg h-100 p-3 d-flex align-items-center">
                                                    <p className="main-content-first-section-text ">
                                                        A global, asset-light, fully integrated company, Delta Corp Holdings is engaged
                                                        in transportation/logistics services, asset management and servicing the
                                                        maritime industry supply chain.
                                                    </p>
                                                </div>
                                            </div>
                                            <div className="col-md-5 col-12">
                                                <StaticImage
                                                    src="../images/other/main-img.jpg"
                                                    className="main-content-first-section-image"
                                                    imgStyle={{ objectFit: "cover" }}
                                                />
                                            </div>
                                        </div>
                                    </div>

                                    <div className="main-content-second-section pb-md-5 pb-3">
                                        <div className="row">
                                            <div className="col-12">
                                                <p className="main-content-second-section-text">
                                                    Headquartered in London, our holding company oversees a network of offices in 10
                                                    countries throughout Europe, the Middle East and Asia. We currently operate through
                                                    three subsidiaries: Bulk Logistics, Energy Logistics, and Asset Management for
                                                    logistic assets. We believe our integrated operations provide us with competitive
                                                    advantages that allow us to maximize our market position, and that our business
                                                    offers unrivaled scalability within the maritime and logistics industry.
                                                </p>

                                                <p className="main-content-second-section-text">
                                                    As global trade patterns transform due to economic shifts and use of technology,
                                                    Delta seeks to navigate high-growth and niche markets with an adventurous spirit of
                                                    discovery. Learn more about our subsidiaries:
                                                </p>
                                            </div>
                                        </div>
                                    </div>

                                    <div className="main-content-subsidiaries pb-md-5 pb-3">
                                        <div className="row row-cols-1 row-cols-md-2 row-cols-xl-3 row-cols-xl-4 gy-4">
                                            <div className="col">
                                                <div className="card card-product">
                                                    <div className="card-body px-4 d-flex flex-column">
                                                        <img
                                                            className="img-colored"
                                                            src={BulkLogistics}
                                                            height="60"
                                                            alt="Delta Energy logo"
                                                        />
                                                        <img
                                                            className="img-white"
                                                            src={BulkLogisticsWhite}
                                                            height="60"
                                                            alt="Delta Energy logo"
                                                        />
                                                        <p className="text-light my-4">
                                                            Facilitating international trade between the world's major economies as
                                                            well as emerging markets.
                                                        </p>
                                                        <Link
                                                            to="/bulk-logistics"
                                                            style={{ textDecoration: "none" }}
                                                            className="btn card-link"
                                                        >
                                                            Bulk logistics <span>{">>"}</span>
                                                        </Link>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col">
                                                <div className="card card-product">
                                                    <div className="card-body px-4 d-flex flex-column">
                                                        <img
                                                            className="img-colored"
                                                            src={EnergyLogistics}
                                                            height="60"
                                                            alt="Delta Energy logo"
                                                        />
                                                        <img
                                                            className="img-white"
                                                            src={EnergyLogisticsWhite}
                                                            height="60"
                                                            alt="Delta Energy logo"
                                                        />
                                                        <p className="text-light my-4">
                                                            A physical supplier and trader of marine fuels and lubricants.
                                                        </p>
                                                        <Link
                                                            to="/energy-logistics"
                                                            style={{ textDecoration: "none" }}
                                                            className="btn card-link"
                                                        >
                                                            Energy logistics <span>{">>"}</span>
                                                        </Link>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col">
                                                <div className="card card-product">
                                                    <div className="card-body px-4 d-flex flex-column">
                                                        <img
                                                            className="img-colored"
                                                            src={AssetManagement}
                                                            height="60"
                                                            alt="Delta Energy logo"
                                                        />
                                                        <img
                                                            className="img-white"
                                                            src={AssetManagementWhite}
                                                            height="60"
                                                            alt="Delta Energy logo"
                                                        />
                                                        <p className="text-light my-4">
                                                            Ship manager strategically located across Dubai, Copenhagen, Singapore &
                                                            India.
                                                        </p>
                                                        <Link
                                                            to="/asset-management"
                                                            style={{ textDecoration: "none" }}
                                                            className="btn card-link"
                                                        >
                                                            Asset Management <span>{">>"}</span>
                                                        </Link>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col">
                                                <div className="card card-product">
                                                    <div className="card-body px-4 d-flex flex-column">
                                                        <img
                                                            className="img-colored"
                                                            src={LogisticPark}
                                                            height="60"
                                                            alt="Delta Energy logo"
                                                        />
                                                        <img
                                                            className="img-white"
                                                            src={LogisticParkWhite}
                                                            height="60"
                                                            alt="Delta Energy logo"
                                                        />
                                                        <p className="text-light my-4">
                                                        Logistics Parks enhance freight movement and reduce logistics costs.
                                                        </p>
                                                        <Link
                                                            to="/logistic-park"
                                                            style={{ textDecoration: "none" }}
                                                            className="btn card-link"
                                                        >
                                                            Logistics Park <span>{">>"}</span>
                                                        </Link>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    {/* <div className="main-content-download py-md-5 py-3">
                                        <div className="row">
                                            <div className="col-12">
                                                <h3 className="text-center">
                                                    Click below to download an introduction to Delta Corp Holdings:
                                                </h3>
                                                <button className="btn main-content-download-button d-block my-md-5 my-3 mx-auto">
                                                    <FontAwesomeIcon icon={faFilePdf} className="me-2" /> Delta Corp Holdings
                                                </button>
                                            </div>
                                        </div>
                                    </div> */}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </Layout>
)

export default IndexPage
